import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router';
import moment from "moment"
import { getStorage, ref, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import axios from 'axios';
Vue.use(Vuex)
/* eslint-disable */
import {
  db, storage
} from "../firebase/firebase";
import {
  collection,
  getDocs,
  where,
  query,
  doc,
  deleteDoc,
  updateDoc,
  setDoc,
  addDoc,
  getDoc,
  onSnapshot,
  DocumentReference,
  orderBy,
  limit,
  startAt,
  startAfter,
  endAt
} from "firebase/firestore";

import mqtt from 'mqtt';

//HER KULLANICI BAĞIMSIZ OLSUN İSTİYORUZ
var clientID = (Math.random() + 1).toString(36).substring(7)



const options = {
  protocol: 'ws', // ws veya wss (güvenli) protokolü kullanın
  clientId: clientID,
  username: 'arsadenizliMqtt', // MQTT broker kullanıcı adınız
  password: 'ApGdKTCU8E', // MQTT broker şifreniz
  keepalive: 60,
  reconnectPeriod: 1000,
  connectTimeout: 30 * 1000,
};

const sorguClient = mqtt.connect('ws://165.22.21.39:9001', options);

sorguClient.on('connect', function () {
  console.log('Connected to MQTT broker');
  sorguClient.subscribe('/sorgular/' + clientID, function (err) {
    if (!err) {
      console.log('Subscribed to topic');
    } else {
      console.error('Subscription error:', err);
    }
  });
});

sorguClient.on('message', function (topic, message) {
  console.log("MQTT GELEN", message.toString())
  const data = JSON.parse(message.toString());


  // Gelen veriyi state'e veya uygun bir değişkene kaydedin
  store.commit('SetTempSorgu', data); // Vuex kullanıyorsanız
});

sorguClient.on('error', function (err) {
  console.error('Connection error:', err);
});

sorguClient.on('reconnect', function () {
  console.log('Reconnecting...');
});

const store = new Vuex.Store({
  state: {
    server: "http://165.22.21.39:1880",
    resimYolu: "http://images.landex.com.tr/images/landexoffice/haritalogo.jpg",
    klasorAdi: "landexoffice",
    firma: "OFFİCE GAYRİMENKUL",
    mqttClient: sorguClient,
    mqttClientID: clientID,
    ilanlar: [],
    alicilar: [],
    searchFilter: [],
    tempMerkezefendi: null,
    tempVeri: null,
    tempSorgu: null,

    tempVeriDep: [],
    employee: [],
    mesai: [],
    holidays: [],
    Departmans: [],
    Designaiton: [],
    traningType: [],
    trainer: [],
    training: [],
    attendance: [],
    Leaves: [],
    sch: [],
    company: {},
    client: [],
    tempSearch: [],
    picture: "",
    roles: [],
    uploadValue: 0,
    tabloDinle: [],
    shiftTable: [],
    dailySchedule: [],
    authPages: [],
    week: []

  },
  getters: {

    GetMqttClient(state) {
      return state.mqttClientID
    },
    GetSunucu(state) {
      return state.server
    },
    Getilanlar(state) {
      return state.ilanlar
    },
    GetAlicilar(state) {
      return state.alicilar
    },

    GetAuthPage(state) {
      return state.authPages
    },
    GetSearchFilter(state) {
      return state.searchFilter
    },
    GetMesai(state) {
      return state.mesai
    },
    GetRoles(state) {
      return state.roles
    },
    GetMerkezefendiTempVeri(state) {
      return state.tempMerkezefendi
    },
    GetTempVeri(state) {
      return state.tempVeri
    },
    GetTempSorgu(state) {
      return state.tempSorgu
    },
    GetTempVeriDep(state) {
      return state.tempVeriDep
    },

    GetAttendance(state) {
      return state.attendance
    },
    GetTabloDinle(state) {
      return state.tabloDinle
    },
    GetLeaves(state) {
      return state.Leaves
    },
    GetEmployes(state) {
      return state.employee
    },
    GetDailySchedule(state) {
      return state.dailySchedule
    },
    Getholidays(state) {
      return state.holidays
    },
    GetDepartment(state) {
      return state.Departmans
    },
    GetDesignation(state) {
      return state.Designaiton
    },
    GetTrainingType(state) {
      return state.traningType
    },
    GetShiftTable(state) {
      return state.shiftTable
    },
    GetTrainer(state) {
      return state.trainer
    },
    GetTraining(state) {
      return state.training
    },

    GetSch(state) {
      return state.dailySchedule
    },
    Getcompany(state) {
      return state.company
    },
    Getclient(state) {
      return state.client
    },

    GetTempSearch(state) {
      return state.tempSearch
    },
    GetWeek(state) {
      return state.week;
    },

  },
  mutations: {

    SetMqttClientID(state, payload) {

      state.mqttClientID = payload
    },
    Setilanlar(state, payload) {

      state.ilanlar = payload
    },

    SetAlicilar(state, payload) {

      state.alicilar = payload
    },
    SetWeek(state, payload) {

      state.week = payload
    },
    SetAuthPage(state, payload) {

      state.authPages = payload
    },
    SetSearchFilter(state, payload) {

      state.searchFilter = payload
    },
    SetMesai(state, payload) {

      state.mesai = payload
    },
    SetRoles(state, payload) {

      state.roles = payload
    },
    SetTempVeri(state, payload) {

      state.tempVeri = payload
    },
    SetTempSorgu(state, payload) {

      state.tempSorgu = payload
    },

    SetMerkezefendiTempVeri(state, payload) {
      state.tempMerkezefendi = payload
    },

    SetTempVeriDep(state, payload) {

      state.tempVeriDep = payload
    },
    SetAttendance(state, payload) {

      state.attendance = payload
    },
    SetTabloDinle(state, payload) {

      state.tabloDinle = payload
    },
    //RESİM YÜKLEME ALANI

    SetUpload(state, payload) {

      state.uploadValue = payload
    },


    SetLeaves(state, payload) {

      state.Leaves = payload
    },
    SetPicture(state, payload) {

      state.picture = payload
    },
    SetEmployee(state, payload) {

      state.employee = payload
    },
    SetDailyScheduling(state, payload) {

      state.dailySchedule = payload
    },
    SetShiftTable(state, payload) {
      state.shiftTable = payload
    },


    SetTempSearch(state, payload) {

      state.tempSearch.push(payload)
    },
    ChangeEmployee(state, payload) {
      const fin = state.employee.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.employee.splice(fin, 1, obj)
    },
    DeleteEmployee(state, payload) {
      const fin = state.employee.findIndex(el => {
        console.log(el.id);
        return el.id == payload.data.id
      });
      console.log(fin);
      state.employee.splice(fin, 1)
    },

    /// Holidays

    SetHolidays(state, payload) {
      state.holidays = payload
    },
    ChangeHoli(state, payload) {
      const fin = state.holidays.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.holidays.splice(fin, 1, obj)
    },
    DeleteHoli(state, payload) {
      const fin = state.holidays.findIndex(el => {
        return el.id == payload.data.id
      });
      console.log(fin);
      state.holidays.splice(fin, 1)
    },
    /////  Traning SECTION //////////////
    SetTrainingType(state, payload) {
      state.traningType = payload
    },

    SetTrainer(state, payload) {
      state.trainer = payload
    },
    SetTraining(state, payload) {
      state.training = payload
    },
    ChangeTrainingType(state, payload) {
      const findt = state.traningType.findIndex(el => {
        return el.id = payload.id
      });
      console.log(findt);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.traningType.splice(findt, 1, obj)
    },
    DeleteTrainingType(state, payload) {
      const findt = state.traningType.findIndex(el => {
        return el.id == payload.data.id
      });
      state.traningType.splice(findt, 1)
    },
    DeleteTrainer(state, payload) {
      const findt = state.trainer.findIndex(el => {
        return el.id == payload.data.id
      });
      state.trainer.splice(findt, 1);
    },

    DeleteShiftTable(state, payload) {
      const findt = state.trainer.findIndex(el => {
        return el.id == payload.data.id
      });
      state.trainer.splice(findt, 1);
    },

    DeleteShiftTable(state, payload) {
      const findt = state.trainer.findIndex(el => {
        return el.id == payload.data.id
      });
      state.trainer.splice(findt, 1);
    },
    ////////

    /// DEparmant   
    SetDepartment(state, payload) {
      state.Departmans = payload
    },
    ChangeDept(state, payload) {
      const fin = state.Departmans.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.Departmans.splice(fin, 1, obj)
    },
    DeleteDept(state, payload) {
      const fin = state.Departmans.findIndex(el => {
        return el.id == payload.data.id
      });
      console.log(fin);
      state.Departmans.splice(fin, 1)
    },

    // DESİGNATİON
    SetDesignation(state, payload) {
      state.Designaiton = payload
    },
    ChangeDest(state, payload) {
      const fin = state.Designaiton.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.Designaiton.splice(fin, 1, obj)
    },
    DeleteDest(state, payload) {
      const fin = state.Designaiton.findIndex(el => {
        return el.id == payload.data.id
      });
      console.log(fin);
      state.Designaiton.splice(fin, 1)
    },

    /// SCHEDULİNG
    SetSch(state, payload) {
      //state.sch.push(payload)
      state.sch = payload

    },
    ChangeSch(state, payload) {
      const fin = state.sch.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.sch.splice(fin, 1, obj)
    },
    DeleteSch(state, payload) {
      const fin = state.sch.findIndex(el => {
        return el.id == payload.data.id
      });
      console.log(fin);
      state.sch.splice(fin, 1)
    },


    // CompanySettings
    SetCompany(state, payload) {
      state.company = payload
    },
    ChangeCompany(state, payload) {
      state.company = payload.data
    },

    // CLİENT
    SetClient(state, payload) {
      state.client = payload

    },

    ChangeClient(state, payload) {
      const fin = state.client.findIndex(el => {
        return el.id == payload.id
      });
      console.log(fin);
      let obj = Object.assign(payload.data, {
        id: payload.id
      })
      state.client.splice(fin, 1, obj)
    },
    DeleteClient(state, payload) {
      const fin = state.client.findIndex(el => {
        return el.id == payload.data.id
      });
      console.log("Sate", fin);
      state.client.splice(fin, 1)
    },







  },
  actions: {


    haftaGetir(context, payload) {


      var today = payload.tarih != null ? payload.tarih : new Date();


      var days = []
      for (var i = 1; i < 8; i++) {
        var tempDate = new Date(today.setDate(today.getDate() - today.getDay() + i))
        days.push({ day: tempDate.getDate(), month: tempDate.getMonth() + 1, year: tempDate.getFullYear() })
      }

      context.commit("SetWeek", days)
    },

    async resimYukle(context, payload) {

      const storage = getStorage();
      const storageRef = ref(storage, 'dosya/' + payload.name);

      const uploadTask = await uploadBytes(storageRef, payload);

      return getDownloadURL(uploadTask.ref)


    },

    async Tanimlamalar(context, payload) {
      // const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      // // console.log("GİRİŞ YAPAN", uid);
      // // await new Promise(async (resolve, reject) => {

      // if (context.getters.GetWeek.length == 0) context.dispatch("haftaGetir", {})

      // let hafta = context.getters.GetWeek

      // let firstDay = hafta[0].year + "-" + hafta[0].month + "-" + hafta[0].day
      // let lastDay = hafta[6].year + "-" + hafta[6].month + "-" + hafta[6].day


      // context.dispatch("sorgu", ({ tablo: "Client", guncelle: "SetClient", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "Employee", sirala: "FirstName", guncelle: "SetEmployee", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // await context.dispatch("sorgu", ({ tablo: "DailyScheduling", guncelle: "SetDailyScheduling", kosul: [{ query: ["CompanyUid", "==", uid] }, { query: ["startDate", ">=", new Date(firstDay)] }, { query: ["startDate", "<=", new Date(lastDay)] }] }))
      // context.dispatch("sorgu", ({ tablo: "Department", guncelle: "SetDepartment", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "Designation", guncelle: "SetDesignation", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "ShiftTable", guncelle: "SetShiftTable", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "Leaves", guncelle: "SetLeaves", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // context.dispatch("sorgu", ({ tablo: "CompanySettings", guncelle: "SetCompany", kosul: [{ query: ["CompanyUid", "==", uid] }] }))



      // //   resolve()

      // // })

    },

    async isTablosu(context, payload) {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      console.log("İŞ TABLOSU ", payload)
      let personeller, mesailer = []
      if (payload !== undefined) {
        //PERSONEL SORGUSU
        if (payload.sorgu.EmployeeID == "0" || payload.sorgu.EmployeeID === undefined) {
          personeller = context.getters.GetEmployes
        } else {
          personeller = [await context.dispatch("sorguDoc", await context.dispatch("sorguRef", ({ tablo: "Employee", id: payload.sorgu.EmployeeID })))]
        }

        //CLİENT SORGUSU
        if (payload.sorgu.clientID == "0" || payload.sorgu.clientID === undefined) {
          mesailer = context.getters.GetDailySchedule
        } else {
          mesailer = await context.dispatch("sorguTable", ({ tablo: "DailyScheduling", kosul: [{ query: ["clientID", "==", payload.sorgu.clientID] }] }))
        }

      } else {
        personeller = context.getters.GetEmployes
        mesailer = context.getters.GetDailySchedule
      }



      //MEVCUT SET EDİLMİŞ HAFTAYA BAKILIYOR
      let hafta = context.getters.GetWeek

      // console.log("MESAİLER",mesailer)
      let sonuc = new Promise((resolve, reject) => {

        //ÖNCE TÜM PERSONELLER LİSTELENİYOR
        for (let item of personeller) {

          var personel = mesailer.filter(p => p.EmployeeID == item.id)
          //  console.log("PERSONEL MESAİLER", personel)
          item.gunler = [];
          //PERSONEL VARMI
          for (var personelMesai of personel) {

            //HAFTANIN GÜNLERİ GETİRİLİYOR
            for (let haftagun in hafta) {
              // ÇAKIŞAN PERSONEL AYIKLANIYOR
              if (hafta[haftagun].year == personelMesai.Year && hafta[haftagun].month == personelMesai.Month && hafta[haftagun].day == personelMesai.Day) {
                item.gunler.push({ gun: haftagun, mesai: personelMesai })
                // console.log("PERSONELGUN", personelMesai)
              }


            }

          }

        }
        //console.log("TANIMLAMALAR BİTTİ")
        resolve(personeller);

      })
      sonuc.then(z => {
        context.commit("SetMesai", z);


      })




    },

    //MÜSAİT PERSONEL ARANIYOR
    async MusaitPersonel(context, payload) {

      //console.log("MusaitPersonel",payload)
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      await context.dispatch("sorgu", ({ tablo: "Leaves", guncelle: "SetLeaves", kosul: [{ query: ["CompanyUid", "==", uid] }] }))
      // await context.dispatch("sorgu", ({ tablo: "Holidays", guncelle: "SetHolidays", kosul: [{ query: ["CompanyUid", "==", uid] }] }))


      let tempDesignationID = await context.dispatch("sorguRef", { tablo: "Designation", id: payload.DesignationID })
      let personeller = await context.dispatch("sorguTable", ({ tablo: "Employee", kosul: [{ query: ["CompanyUid", "==", uid] }, { query: ["DesignationID", "array-contains", tempDesignationID] }] }))
      let personelShift = await context.dispatch("sorguTable", ({ tablo: "DailyScheduling", kosul: [{ query: ["CompanyUid", "==", uid] }, { query: ["startDate", ">=", new Date(payload.startDate)] }, { query: ["startDate", "<=", new Date(payload.endDate)] }] }))
      //console.log("personelShift", personelShift)


      let leaves = context.getters.GetLeaves
      let holidays = context.getters.Getholidays

      let uygunPersonel = []


      for (let item of personeller) {

        item.availability = []

        //TATİL GÜNLERİNE BAKILIYOR
        var holidayDays = holidays.find(p => ((p.startDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.startDate.toDate().getTime() >= new Date(payload.startDate).getTime())
          || (p.endDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.endDate.toDate().getTime() >= new Date(payload.startDate).getTime()))
        )
        if (holidayDays) {
          //console.log("TATİL GÜNÜ", holidayDays)

          item.availability.push({ "area": "Holiday", "data": holidayDays })
        }

        var personelLeave = leaves.find(p => p.Personel == item.id && ((p.startDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.startDate.toDate().getTime() >= new Date(payload.startDate).getTime())
          || (p.endDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.endDate.toDate().getTime() >= new Date(payload.startDate).getTime()))
        )

        //İZİN DURUMUNA BAKILIYOR
        if (personelLeave) {
          item.availability.push({ "area": "Leave", "data": personelLeave })
        }

        //ÇALIŞMA DURUMUNA BAKILIYOR
        var personelScheduling = personelShift.find(p => p.EmployeeID == item.id && ((p.startDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.startDate.toDate().getTime() >= new Date(payload.startDate).getTime())
          || (p.endDate.toDate().getTime() <= new Date(payload.endDate).getTime() && p.endDate.toDate().getTime() >= new Date(payload.startDate).getTime()))
        )


        if (personelScheduling) {
          item.availability.push({ "area": "Working", "data": personelScheduling })
        }


        else {
          // uygunPersonel.push(item)
        }
      }

      console.log("UYGUN PERSONEL", uygunPersonel)





      return personeller.map((t) => {


        if (t.hasOwnProperty("availability")) {
          if (t.availability.length > 0) {

            return {
              name: t.FirstName + ' ' + t.LastName + ' -- ' + t.availability.map(p => p["area"]).reduce((a, b) => a + "," + b),
              id: t.id, $isDisabled: true
            };
          }

        }
        return {
          name: t.FirstName + ' ' + t.LastName,
          id: t.id,
        };

      })





      // for (let item of personeller) {



      //   var personelLeave = leaves.find(p => p.Personel == item.id)


      //    //İZİN DURUMUNA BAKILIYOR
      //    if(personelLeave)
      //    { 
      //     console.log("PERSONEL LEAVE",personelLeave)


      //     //TARİH FARKINA BAKILIYOR
      //     let baslangicfark=Math.floor(new Date(payload.startDate) - new Date(personelLeave.startdate)) / (1000 * 60 * 60 * 24);
      //     let baslangicbitisfark=Math.floor( new Date(payload.startDate)-new Date(personelLeave.enddate)) / (1000 * 60 * 60 * 24);

      //     let bitisbaslangicfark=Math.floor(new Date(payload.endDate) - new Date(personelLeave.startdate)) / (1000 * 60 * 60 * 24);
      //     let bitisbitisfark=Math.floor( new Date(payload.endDate)-new Date(personelLeave.enddate)) / (1000 * 60 * 60 * 24); 

      //     console.log("baslangicfark",baslangicfark)
      //     console.log("baslangicbitisfark",baslangicbitisfark)

      //     console.log("bitisbaslangicfark",bitisbaslangicfark)
      //     console.log("bitisbitisfark",bitisbitisfark)
      //     if((baslangicfark<0 || baslangicbitisfark>0)  && bitisbitisfark >0)
      //     {

      //       uygunPersonel.push(item)
      //     }
      //    }
      //    else{
      //     // uygunPersonel.push(item)
      //    }
      // }

      // console.log("UYGUN PERSONEL",uygunPersonel)





      //   return uygunPersonel


    },

    showToast({ commit }, { message, type = 'success', duration = 3000, position = 'top-right' }) {
      Vue.$toast.open({
        message: message,
        type: type,
        duration: duration,
        position: position
      });
    },

    //ORTAK SORGU ALANI
    async axiosSorgu(context, payload) {

      var kullaniciBilgi = localStorage.getItem("storedData")
      //return (await axios.post(context.getters.GetSunucu+"/"+payload.tablo,{kullanici:kullaniciBilgi,...payload}))

      try {
        const sonuc = (await axios.post(context.getters.GetSunucu + "/" + payload.tablo, { kullanici: kullaniciBilgi, ...payload }))
        return sonuc
      } catch (error) {

        //  Vue.$toast.open({
        //     message: this.$t("KULLANICI BİLGİLERİ HATALI"),
        //     type: "error",
        //   });



        // Hata sayfasına yönlendirme
        // router.push({
        //   name: 'error',
        //   params: { error: error.message || 'Bilinmeyen bir hata oluştu' }
        // })
        context.dispatch('showToast', {
          message: 'BU İŞLEME YETKİNİZ YOK !!!',
          type: 'error',
          duration: 5000,
          position: 'top'
        });




        // console.log("SORGU SONUCU",error) 
      }







    },

    async axiosIslem(context, payload) {
      var kullaniciBilgi = localStorage.getItem("storedData")

      // return await axios.post(context.getters.GetSunucu+"/"+payload.tablo,{kullanici:kullaniciBilgi,sorgu:payload.sorgu})
      try {
        const sonuc = await axios.post(context.getters.GetSunucu + "/" + payload.tablo, { kullanici: kullaniciBilgi, sorgu: payload.sorgu })
        return sonuc
      } catch (error) {

        // Hata sayfasına yönlendirme
        // router.push({
        //   name: 'error',
        //   params: { error: error.message || 'Bilinmeyen bir hata oluştu' }
        // })
        context.dispatch('showToast', {
          message: 'BU İŞLEME YETKİNİZ YOK !!!',
          type: 'error',
          duration: 5000,
          position: 'top'
        });


      }

    },


    async sorgu(context, payload) {


      console.log("PARAMETRELER", payload)
      //let docRef;
      // if (payload.kosul) {

      //   if (payload.sirala) {

      //     docRef = query(collection(db, payload.tablo), ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])), orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"), limit(payload.limit != null ? payload.limit : 20))
      //   }
      //   else {

      //     docRef = query(collection(db, payload.tablo), ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])), limit(payload.limit != null ? payload.limit : 20))
      //   }


      // } else {


      //   if (payload.sirala) {

      //     docRef = query(collection(db, payload.tablo), orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"), limit(payload.limit != null ? payload.limit : 20));
      //   } else {
      //     docRef = query(collection(db, payload.tablo), limit(payload.limit != null ? payload.limit : 20));
      //   }
      // }



      // let docRef = query(collection(db, payload.tablo));

      // if (payload.kosul) docRef = query(docRef, ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])))
      // if (payload.sirala) docRef = query(docRef, orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"))
      // if (payload.limit) docRef = query(docRef, limit(payload.limit))
      // if (payload.pagination && !payload.sirala) docRef = query(docRef, orderBy(payload["pagination"]["orderBy"], payload.siralaYon != null ? payload.siralaYon : "desc"), startAt(payload["pagination"]["startAt"]), endAt(payload["pagination"]["endAt"]))




      // const docSnap = await getDocs(docRef);
      // console.log("SONUC", docSnap.docs)


      // if (payload.guncelle) context.commit(payload.guncelle, docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))

    },
    async sayfaYetki(context, payload) {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      const docRef = doc(db, "Users", uid);
      const docSnap = await getDoc(docRef);
      //console.log("YETKİ",docSnap.data())
      context.commit("SetAuthPage", docSnap.data())

    },

    async sorguRef(context, payload) {

      return await doc(db, payload.tablo, payload.id);
    },
    async sorguDoc(context, payload) {


      const docSnap = await getDoc(payload);

      return Object.assign(docSnap.data(), { id: docSnap.id });
    },
    async sorguTable(context, payload) {


      // // let docRef;
      // // if (payload.kosul) {

      // //   if (payload.sirala) {

      // //     docRef = query(collection(db, payload.tablo), ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])), orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"), limit(payload.limit != null ? payload.limit : 20))
      // //   }
      // //   else {

      // //     docRef = query(collection(db, payload.tablo), ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])), limit(payload.limit != null ? payload.limit : 20))
      // //   }


      // // } else {


      // //   if (payload.sirala) {

      // //     docRef = query(collection(db, payload.tablo), orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"), limit(payload.limit != null ? payload.limit : 20));
      // //   } else {
      // //     docRef = query(collection(db, payload.tablo), limit(payload.limit != null ? payload.limit : 20));
      // //   }
      // // }

      // let docRef = query(collection(db, payload.tablo));

      // if (payload.kosul) docRef = query(docRef, ...payload.kosul.map(t => where(t.query[0], t.query[1], t.query[2])))
      // if (payload.sirala) docRef = query(docRef, orderBy(payload.sirala, payload.siralaYon != null ? payload.siralaYon : "desc"))
      // if (payload.limit) docRef = query(docRef, limit(payload.limit))
      // if (payload.pagination && !payload.sirala) docRef = query(docRef, orderBy(payload["pagination"]["orderBy"], payload.siralaYon != null ? payload.siralaYon : "desc"), startAt(payload["pagination"]["startAt"]), endAt(payload["pagination"]["endAt"]))





      // const docSnap = await getDocs(docRef);
      // console.log("SONUC", docSnap.docs)
      // console.log("PARAMETRELER", payload)

      // return docSnap.docs.map(p => Object.assign(p.data(), { id: p.id }))

    },


    async personelAra(context, payload) {

      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      const docRef = query(
        collection(db, "Employee"),
        where("CompanyUid", "==", uid), where("FirstName", "array-contains", [payload])
      );

      const docSnap = await getDocs(docRef); // Normal olarak data çekildi
      docSnap.forEach((el) => {
        context.commit("SetEmployee", Object.assign(el.data(), {
          id: el.id
        }))

      })
    },
    async clientAra(context, payload) {

      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      const docRef = query(
        collection(db, "Client"),
        where("CompanyUid", "==", uid), where("ClientName", "array-contains", [payload])
      );

      const docSnap = await getDocs(docRef);
      docSnap.forEach((el) => {
        context.commit("SetClient", Object.assign(el.data(), {
          id: el.id
        }))

      })
    },

    async tumPersonel(context, payload) {

      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      const docRef = query(collection(db, "Employee"), where("CompanyUid", "==", uid));

      const docSnap = await getDocs(docRef); // Normal olarak data çekildi

      //  context.commit("SetEmployee", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
      return docSnap.docs.map(t => t.data());
    },
    async personelGetir(context, payload) {
      const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      const docRef = doc(db, "Employee", payload.id, where("CompanyUid", "==", uid));
      const docSnap = getDoc(docRef);

      return docSnap;


    },
    async clientGetir(context, payload) {

      const docRef = doc(db, "Client", payload.id);
      const docSnap = getDoc(docRef);

      return docSnap;


    },

    async leavesGetir(context, payload) {
      console.log(payload)
      let docRef;
      if (payload) {


        docRef = query(collection(db, "Leaves"), ...payload.map(t => where(t.query[0], t.query[1], t.query[2])))

      } else {

        docRef = query(collection(db, "Leaves"));
      }


      const docSnap = await getDocs(docRef);

      context.commit("SetLeaves", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))

    },



    async departmanGetir(context, payload) {

      this.state.Departmans = []
      const docRef = query(collection(db, "Department"), where("CompanyUid", "==", uid));

      const docSnap = await getDocs(docRef); // Normal olarak data çekildi

      context.commit("SetDepartment", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))

    },

    async holidaysGetir(context, payload) {

      const docRef = query(collection(db, "Holidays"));

      const docSnap = await getDocs(docRef); // Normal olarak data çekildi

      context.commit("SetHolidays", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))

    },
    async getTrainingType(context, payload) {
      const docRef = query(collection(db, "TrainingType"));
      const docSnap = await getDocs(docRef);
      context.commit("SetTrainingType", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    },

    async getShiftTable(context, payload) {
      this.state.shiftTable = []
      const docRef = query(collection(db, "ShiftTable"), where("CompanyUid", "==", uid));
      const docSnap = await getDocs(docRef);
      context.commit("SetShiftTable", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
      console.log("SHİFTTABLE", docSnap);
    },
    async getSch(context, payload) {
      console.log(payload)
      const docRef = query(collection(db, "DailyScheduling"));
      const docSnap = await getDocs(docRef);
      context.commit("SetDailyScheduling", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
      console.log("SCHEDULE DATALARI", docSnap);

    },
    //     async mesaiGetir(context, payload) { //Schedule Sayfası 

    //      console.log("GELEN VERİ",payload)

    //       let docRef;
    //       if (payload) {

    //         docRef = query(collection(db, "DailyScheduling"), ...payload.map(t => where(t.query[0], t.query[1], t.query[2])))

    //       } else {

    //         docRef = query(collection(db, "DailyScheduling"));
    //       }


    //       const docSnap = await getDocs(docRef);

    //       context.commit("SetDailyScheduling", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    // console.log("SET EDİLEN")
    //     },


    async getClients(context, payload) {
      // this.state.client=[]
      // const docRef = query(collection(db, "Client"));
      // const docSnap = await getDocs(docRef);
      // context.commit("SetClient", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    },



    // async getShiftTable(context, payload) {
    //   const docRef = query(collection(db, "ShiftTable"));
    //   const docSnap = await getDocs(docRef);
    //   context.commit("SetShiftTable", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    //   console.log("SHİFTTABLE", docSnap);
    // },
    // async getSch(context, payload) {
    //   const docRef = query(collection(db, "DailyScheduling"));
    //   const docSnap = await getDocs(docRef);
    //   context.commit("SetDailyScheduling", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    //   console.log("SCHEDULE DATALARI", docSnap);

    // },
    // async getClients(context, payload) {
    //   this.state.client=[]
    //   const docRef = query(collection(db, "Client"));
    //   const docSnap = await getDocs(docRef);
    //   context.commit("SetClient", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    //   console.log("CLIENTS", docSnap);
    // },
    // async shiftLeaves(context,payload){
    //   const docRef=query(collection(db,"Leaves"));
    //   const docSnap=await getDocs(docRef);
    //   context.commit("SetLeaves",docSnap.docs.map(n=>Object.assign(n.data(),{id:n.id})));
    //   console.log("İZİN BİLGİLERİ", docSnap);

    //    },


    async leavesGetir(context, payload) {
      console.log("ikinci", payload)
      let docRef;
      if (payload) {


        docRef = query(collection(db, "Leaves"), ...payload.map(t => where(t.query[0], t.query[1], t.query[2])))

      } else {

        docRef = query(collection(db, "Leaves"));
      }


      const docSnap = await getDocs(docRef);

      context.commit("SetLeaves", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
      console.log("PERSONEL İZİNLİ OLANLAR", docSnap);

    },











    async GetTrainer(context, payload) {
      const docRef = query(collection(db, "Trainer"));
      const docSnap = await getDocs(docRef);
      context.commit("SetTrainer", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))
    },
    async GetTrainingData(context, payload) {

      const docRef = query(collection(db, "Training"));
      const docSnap = await getDocs(docRef);

      context.commit("SetTraining", docSnap.docs.map(p => Object.assign(p.data(), { id: p.id })))

    },



    async fetchdata(context, payload) {
      // const uid = JSON.parse(localStorage.getItem("storedData")).CompanyUid;
      // //console.log("PAYLOAD",payload.db);
      // const docRef = query(
      //   collection(db, payload.db),
      //   where("CompanyUid", "==", uid)
      // );
      // // console.log("YOKSA HATALI DB", docRef);
      // const docSnap = await getDocs(docRef); // Normal olarak data çekildi

      // context.commit("SetHolidays", docSnap.docs.map(p => p.data()))
      // docSnap.forEach((el) => {
      //   context.commit(payload.mt, Object.assign(el.data(), {
      //     id: el.id
      //   }))

      // })



    },

    //? Add
    async AddData(context, payload) {

      console.log("AddData", payload)
      return await addDoc(collection(db, payload.db), payload.veri);



      // docRef.then(data => {
      //   console.log(data);
      //   getDoc(doc(db, data.path)).then(e => {
      //     console.log(e.data());
      //     //  context.commit(payload.mt, e.data())
      //   })
      // })
    },

    //? Update
    async EditData(context, payload) {


      payload.data["updateDate"] = new Date();
      console.log("EditData", payload)
      const data = doc(db, payload.db, payload.id);
      console.log("GÜNCELLEME SONUÇ", data)

      await setDoc(data, payload.data, { merge: true });

      // context.commit(payload.mt, payload)
    },

    //? 
    async DeleteData(content, payload) {
      console.log(payload);
      await deleteDoc(doc(db, payload.db, payload.data.id))
    }
  }
})

export default store;